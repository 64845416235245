import { graphql } from "gatsby"
import React from "react"
import { Layout, SidebarLayout } from "../components/layout"

export const HowToPlay: React.FC<any> = ({ pageContext }) => {
  const { competitionSlug, sidebar, competition } = pageContext

  return (
    <SidebarLayout
      sidebar={{
        competitionSlug,
        navigation: sidebar.navigation,
      }}
      competition={competition}
    >
      <div className="px-5">
        <h1 className="font-primary font-black text-3xl">
          {pageContext.title}
        </h1>

        <div
          className="mt-8 cmsContent"
          dangerouslySetInnerHTML={{ __html: pageContext.content }}
        />
      </div>
    </SidebarLayout>
  )
}

export default HowToPlay
